<template>
  <v-row no-gutters>
    <v-spacer></v-spacer>
    <v-col cols="4" class="text-right">
      <v-btn v-if="needsCancelButton" @click="ignore()">
        {{ ignoreButtonName }}
      </v-btn>
      <v-btn color="success" class="ml-4" @click="approve()">
        {{ successButtonName }}
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import request from "../../request";
export default {
  props: [
    "needsCancelButton",
    "ignoreButtonName",
    "successButtonName",
    "validation",
    "duration",
  ],
  methods: {
    ignore() {
      request.post(
        "/api/optician/ignore-validation-error?validationErrorId=" +
          this.validation.Id,
        null,
        (res) => {
          if (res.success) {
            this.$emit("deleteOutArray", this.validation.Id);
          }
        }
      );
    },
    approve() {
      let url =
        "/api/optician/approve-validation-error?validationErrorId=" +
        this.validation.Id;
      if (this.duration) {
        url += "&duration=" + this.duration;
      }
      request.post(url, null, (res) => {
        if (res.success) {
          this.$emit("deleteOutArray", this.validation.Id);
        }
      });
    },
  },
};
</script>

<style>
</style>